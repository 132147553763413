import * as React from "react"
import { Accordion } from "./accordion"


export const Faq = () => {
  const config  = [
    {
      title:' За&nbsp;что обычно вас ценят клиенты?',
      text:'<p>Нас ценят за&nbsp;комплексный подход. Мы&nbsp;коммуникабельны, умеем слушать и&nbsp;слышать клиента, при\n' +
        '        этом нам не&nbsp;надо объяснять что и&nbsp;как нужно делать&nbsp;&mdash; мы&nbsp;сами все расскажем\n' +
        '        и&nbsp;покажем. У&nbsp;нас никогда не&nbsp;бывает дефицита креативных идей для заказчика, и&nbsp;это будут\n' +
        '        такие идеи&nbsp;&mdash; которые мы&nbsp;точно сможем реализовать.</p>'
    },    {
      title:' Сколько стоит сделать интерьер как на&nbsp;картинках?',
      text:'<p>Можно точно рассчитать стоимость проекта, строительных работ и&nbsp;черновых материалов. Дальше появляется вариативность, по&nbsp;аналогии с&nbsp;выбором авто: у&nbsp;всех автомобилей будет по&nbsp;одному двигателю и&nbsp;по&nbsp;четыре колеса, но&nbsp;ехать и&nbsp;выглядеть они будут по-разному. Так и&nbsp;с&nbsp;интерьерами. Скажем, плитка для отделки стен может стоить 15$ за&nbsp;м<sup>2</sup>, а&nbsp;может и&nbsp;150$, причем на&nbsp;картинках разница между ними не&nbsp;будет слишком разительной, но&nbsp;живьем&nbsp;&mdash; вы&nbsp;ее&nbsp;100% почувствуете. Подобная история будет повторяться по&nbsp;многим составным компонентам интерьера. Одни клиенты не&nbsp;готовы к&nbsp;компромиссам, и&nbsp;хотят получить лучший вариант из&nbsp;возможных, другие&nbsp;&mdash; придерживаются принципа разумной достаточности. Если правило &laquo;средней температуры по&nbsp;больнице&raquo; применить к&nbsp;интерьерам, то&nbsp;можно получить ориентировочную стоимость квадратного метра готового интерьера &laquo;как на&nbsp;картинках&raquo; в&nbsp;среднем: 1000$ за&nbsp;м<sup>2</sup>.</p>'
    },    {
      title:'Занимаетесь&nbsp;ли вы&nbsp;продажей готовых проектов?',
      text:'<p>Нет. Мы&nbsp;занимаемся только индивидуальными проектами.</p>'
    },    {
      title:' Как оплачивается проект?',
      text:'<p>Проект оплачивается в&nbsp;4&nbsp;этапа по&nbsp;25% от&nbsp;стоимости, наличными или переводом на&nbsp;расчетный счет.</p>'
    },    {
      title:' Сколько времени понадобится на&nbsp;ремонт квартиры?',
      text:'<p></p>От&nbsp;6-ти до&nbsp;12-ти месяцев. Важно также понимать, что речь идет о&nbsp;создании уникального интерьера по&nbsp;индивидуальному проекту, не&nbsp;путать с&nbsp;так называемым &laquo;евроремонтом&raquo;.'
    },    {
      title:' Сколько времени занимает строительство дома?',
      text:'<p>В&nbsp;среднем, строительство дома занимает около года. Еще около года потребуется на&nbsp;обустройство интерьера дома и&nbsp;прилегающей территории.</p>'
    },
  ]
  return (
      <Accordion  config={config}/>
  )
}